<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('my_course_schedule')"
                        :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('my_course_schedule')"
                              :isFilter="false"/>
            </template>
            <vue-cal :selected-date="startDate"
                     :time-cell-height="30"
                     :time-step="30"
                     :disable-views="['years', 'year', 'month','days','day']"
                     active-view="week"
                     :hide-weekdays="[]"
                     hide-title-bar
                     :events="events"
                     :locale="$i18n.locale"
                     :time-from="8 * 60"
                     :time-to="21 * 60"
                     :on-event-click="showVueCalModal"
            >
            </vue-cal>
            <CommonModal ref="vueCalModal" size="sm">
                <template v-slot:CommonModalTitle>
                    <i style="font-size: 24px;" class="ri-calendar-event-line"></i>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <b-row>
                            <b-col md="12" class="mb-2">{{selectedEvent.date}}</b-col>
                            <b-col md="12" class="mb-2">{{selectedEvent.title}}</b-col>
                            <b-col md="12" class="mb-2">{{selectedEvent.start_time}} - {{selectedEvent.end_time}}</b-col>
                            <b-col md="12" v-html="selectedEvent.content"></b-col>
                        </b-row>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
    import AppLayout from '@/layouts/AppLayout'
    import Header from '@/layouts/AppLayout/Header'
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

    import VueCal from 'vue-cal'
    import 'vue-cal/dist/vuecal.css'
    import '/src/locales/tr/vuecal.js'
    import moment from 'moment';
    import CommonModal from "@/components/elements/CommonModal";

    // Services
    import CourseScheduleService from '@/services/CourseScheduleService'
    import SettingsService from "@/services/SettingsService"
    import SemesterDateService from "@/services/SemesterDateService";
    import qs from "qs";

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,
            VueCal,
            CommonModal
        },
        data() {
            return {
                startDate: null,
                events: [],
                selectedEvent: {},
                semesterIsActive: true
            }
        },
        metaInfo() {
            return {
                title: this.$t('lesson_timetable')
            }
        },
        methods: {
            getData() {
                if(this.semesterIsActive){
                    CourseScheduleService.getInstructor()
                                         .then(response => {
                                             response.data.data.forEach(item => {
                                                 this.events.push({
                                                     start: moment(this.startDate, 'YYYY-MM-DD').add(item.day - 1, 'days').format('YYYY-MM-DD') + ' ' + item.start_time,
                                                     end: moment(this.startDate, 'YYYY-MM-DD').add(item.day - 1, 'days').format('YYYY-MM-DD') + ' ' + item.end_time,
                                                     title: this.getLocaleText(item, 'classroom_name'),
                                                     content: this.getLocaleText(item, 'course_name') + ' (' + item.course_code + ')'
                                                 })
                                             })
                                         })
                                         .catch(e => {
                                             this.showErrors(e)
                                         })
                }
            },

            showVueCalModal(event, e){
                this.selectedEvent = event
                moment.locale('tr')
                this.selectedEvent.date = moment(event.start).format("DD-MM-YYYY")
                this.selectedEvent.start_time = moment(event.start).format("LT")
                this.selectedEvent.end_time = moment(event.end).format("LT")
                this.$refs.vueCalModal.$refs.commonModal.show()
                e.stopPropagation()
            },

            activeSemesterCheck(){
                let start_date = moment("2023-07-04 09:44:04").unix()
                let end_date = moment("2023-07-05 09:44:04").unix()
                let currentDate = moment().unix()
                //this.semesterIsActive = currentDate > start_date && currentDate < end_date ? true : false
            }

        },

        created() {
            this.activeSemesterCheck()
            this.startDate = moment().startOf('week').add(+1, 'days').format('YYYY-MM-DD');
            this.getData()
        }
    }
</script>
